(function($, window, document) {


  $("#avg").click(function() {
    var $this = $(this);
    if ($this[0].checked) {
      $('.js-form-submit').attr('disabled', false);
    } else {
      $('.js-form-submit').attr('disabled', 'disabled');
    }
  });
})(window.jQuery, window, document);
